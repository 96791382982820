<template>
    <div style="background-color: #E2E5E8;width: 100vw;height: 100vh;min-width: 1030px;min-height: 960px;">
        <div style="height: 1px;"></div>
        <div class="faCla">
            <div class="fa1">
                爱奇艺在线客服
            </div>
            <div class="fa2">
                <div style="height: 1px;"></div>
                <div style="height: 768px;;overflow: auto;" class="funCla">

                    <div class="fa3">
                        <div style="text-align: right;height: 100%;">
                            <div class="fa4"
                                style="background: #00b32d;color: #ffffff; max-width: 85%;margin-right: 9px;padding: 1px 10px;">
                                人工客服
                            </div>
                            <img src="@/assets/user.png" alt="" srcset=""
                                style="width: 32px;vertical-align: top;border-radius: 50%;margin-right: 8px; ">
                        </div>
                    </div>

                    <div class="fa3">
                        <div style="text-align: left;height: 100%;">
                            <img src="@/assets/qypng.png" alt="" srcset=""
                                style="width: 32px;vertical-align: top;border-radius: 50%;margin-left: 10px;background: #fff;">
                            <div class="fa4"
                                style="background: #fff;color: #000; max-width: 27%;margin-left: 9px;line-height: 21px; padding: 7px 10px;">
                                亲爱的用户，请先简要说明您遇到的问题，小艺才能更好地为您转接到合适的人工客服协助您解决问题。
                            </div>
                        </div>
                    </div>

                    <div class="fa3" style="margin: 12px 0px;">
                        <div style="text-align: right;height: 100%;">
                            <div class="fa4"
                                style="background: #00b32d;color: #ffffff; max-width: 85%;margin-right: 9px;padding: 1px 10px;">
                                人工客服
                            </div>
                            <img src="@/assets/user.png" alt="" srcset=""
                                style="width: 32px;vertical-align: top;border-radius: 50%;margin-right: 8px; ">
                        </div>
                    </div>


                    <div class="fa3">
                        <div style="text-align: left;height: 100%;">
                            <img src="@/assets/qypng.png" alt="" srcset=""
                                style="width: 32px;vertical-align: top;border-radius: 50%;margin-left: 10px;">
                            <div class="fa4"
                                style="background: #fff;color: #000; max-width: 27%;margin-left: 9px;line-height: 21px; padding: 7px 11px;">
                                请您耐心等待不要离线，小艺正在为您安排人工客服接入，稍等片刻。
                            </div>
                        </div>
                    </div>

                    <div style="display: flex;align-items: center;justify-content: center;">
                        <span
                            style="text-align: center;font-size: 12px;color: #03B435;margin-top: 18px;background: #ECFAEB;padding: 5px;">
                            正在联系爱奇艺客服，请稍侯...
                        </span>
                    </div>

                    <div style="display: flex;align-items: center;justify-content: center;">
                        <span
                            style="text-align: center;font-size: 12px;color: #03B435;margin-top: 10px;background: #ECFAEB;padding: 5px;">
                            当前客服繁忙，请您耐心排队等待...
                        </span>
                    </div>

                    <div style="display: flex;align-items: center;justify-content: center;">
                        <span
                            style="text-align: center;font-size: 12px;color: #03B435;margin-top: 10px;background: #ECFAEB;padding: 5px;">
                            已接入人工客服
                        </span>
                    </div>



                    <div class="fa3">
                        <div style="text-align: left;height: 100%;">
                            <img src="@/assets/vvPng.png" alt="" srcset=""
                                style="width: 32px;vertical-align: top;border-radius: 50%;margin-left: 10px;background: #fff;">
                            <div class="fa4"
                                style="background: #fff;color: #000; max-width: 55%;margin-left: 9px;line-height: 21px; padding: 7px 11px;">
                                您好，欢迎使用爱奇艺在线客服，请问有什么可以帮您？
                            </div>
                        </div>
                    </div>


                    <div class="fa3" style="margin: 12px 0px;">
                        <div style="text-align: right;height: 100%;">
                            <div class="fa4"
                                style="background: #00b32d;color: #ffffff; max-width: 85%;margin-right: 9px;padding: 5px 10px;text-align: left; line-height: 23px;">
                                <!-- 你好，请你帮我查下2024年6月24日充值的号吗 15136250351爱奇艺黄金会员有没有充值到账 -->
                                <!-- <el-input v-model="inputText" placeholder=""></el-input> -->
                                <p v-if="inputFla" @click="inputFla = false">{{ inputText || '自定义信息' }}
                                </p>
                                <p v-else>
                                    <el-input v-model="inputText" @keydown.enter="inputFla = true"
                                        @change="inputFla = true" style="width: 100%;"></el-input>
                                </p>

                            </div>
                            <img src="@/assets/user.png" alt="" srcset=""
                                style="width: 32px;vertical-align: top;border-radius: 50%;margin-right: 8px; ">
                        </div>
                    </div>

                    <div class="fa3">
                        <div style="text-align: left;height: 100%;">
                            <img src="@/assets/vvPng.png" alt="" srcset=""
                                style="width: 32px;vertical-align: top;border-radius: 50%;margin-left: 10px;background: #fff;">
                            <div class="fa4"
                                style="background: #fff;color: #000; max-width: 60%;margin-left: 9px;line-height: 21px; padding: 7px 11px;">
                                请问您是通过什么方式开通的会员呢，微信/支付宝/银行卡/苹果ID等
                                可以提供一下对应的凭证，这边帮助您查询一下
                            </div>
                        </div>
                    </div>



                    <div class="fa3" style="margin: 12px 0px;">
                        <div style="text-align: right;height: 100%;">
                            <div class="fa4"
                                style="background: #00b32d;color: #ffffff; max-width: 85%;margin-right: 9px;padding: 1px 10px;text-align: left; ">
                                <!-- 你好我在电商渠道购买的 -->
                                <p v-if="inputShop" @click="inputShop = false">{{ inputshop || '自定义信息' }}
                                </p>
                                <p v-else>
                                    <el-input v-model="inputshop" @keydown.enter="inputShop = true"
                                        @change="inputShop = true" style="width: 100%;"></el-input>
                                </p>

                            </div>
                            <img src="@/assets/user.png" alt="" srcset=""
                                style="width: 32px;vertical-align: top;border-radius: 50%;margin-right: 8px; ">
                        </div>
                    </div>

                    <div class="fa3">
                        <div style="text-align: left;height: 100%;">
                            <img src="@/assets/vvPng.png" alt="" srcset=""
                                style="width: 32px;vertical-align: top;border-radius: 50%;margin-left: 10px;background: #fff;">
                            <div class="fa4"
                                style="background: #fff;color: #000; max-width: 60%;margin-left: 9px;line-height: 21px; padding: 7px 11px;">
                                您好，已为您查询，此账号已充值到账
                            </div>
                        </div>
                    </div>

                </div>

                <div style="position: absolute;bottom: 52px;">
                    <div style="display: flex;width: 800px;overflow: hidden;">
                        <div class="sa1" style="color: #00b32d;">
                            评价
                        </div>
                        <div class="sa1">
                            客服电话
                        </div>
                        <div class="sa1">
                            绑官方微信公众号
                        </div>
                        <div class="sa1">
                            如何绑定邮箱
                        </div>
                        <div class="sa1">
                            退登其他设备
                        </div>
                        <div class="sa1">
                            解绑手机号
                        </div>
                        <div class="sa1">
                            手机号非本人
                        </div>
                        <div class="sa1">
                            基础会员的权益
                        </div>
                    </div>

                    <div style="display: flex;width: 740px;height: 42px;background: #fff;margin-top: 10px;">

                        <div style="width: 5%;margin-top: 5px;">
                            <img src="@/assets/jianpan.png" alt="" srcset="" style="width: 34px;">
                        </div>

                        <div style="width: 85%;margin-top: 5px;" class="Son1">
                            <el-input v-model="shuru" placeholder="请输入您的问题" style="width: 100%;"></el-input>
                        </div>

                        <div style="margin-top: 5px;">
                            <img src="@/assets/xiao.png" alt="" srcset="" style="width: 34px;">
                            <img src="@/assets/jia.png" alt="" srcset="" style="width: 34px;">
                        </div>

                    </div>

                </div>




            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'kefu',
    data() {
        return {
            time: this.formatDate2(new Date),
            inputText: '',
            inputshop: '',
            inputFla: true,
            inputShop: true,
            shuru: ''
        }
    },

    mounted() {
        console.log(this.formatDate2(new Date));
    },

    methods: {
        formatDate2(now) {
            let year = now.getFullYear(); //取得4位数的年份
            let month = now.getMonth() + 1; //取得日期中的月份，其中0表示1月，11表示12月
            let date = now.getDate(); //返回日期月份中的天数（1到31）
            let hour = now.getHours(); //返回日期中的小时数（0到23）
            let minute = now.getMinutes(); //返回日期中的分钟数（0到59）
            let seconds = now.getSeconds(); //返回日期中的秒数（0到59）
            return year + "-" + (month <= 9 ? '0' + month : month) + "-" + (date <= 9 ? '0' + date : date) + " " + (hour <= 9 ? '0' + hour : hour) + ":" + (minute <= 9 ? '0' + minute : minute)
            //  + ":" + seconds;
        },
    }
}
</script>

<style lang="less" scoped>
.faCla {
    width: 740px;
    margin: 30px auto;
    height: 93.5vh;
    overflow: hidden;
    min-height: 904px;
}

.fa1 {
    height: 50px;
    line-height: 50px;
    background: #fff;
    font-size: 15px;
    padding-left: 25px;
    color: #000;

}

.fa2 {
    background: #F2F5FA;
    height: 100%;
    position: relative;
}

.fa3 {
    min-height: 35px;
    line-height: 35px;
    margin-top: 10px;
}

.fa4 {
    display: inline-block;
    font-size: 14px;
    width: auto;
    border-radius: 4px;

}

.sa1 {
    color: #000;
    background-color: #fff;
    padding: 5px 12px;
    border-radius: 23px;
    font-size: 11px;
    margin-left: 11px;
}

.Son1 /deep/ .el-input__inner {
    background: #F5F7FA;
    color: #8e939e;
    border: 1px solid #fff;
}

// .Son1 /deep/ .el-input .is-disabled {
//     background: #F5F7FA;
//     color: blue;
//     border: 1px solid #fff;

// }
.Son1 /deep/input::-webkit-input-placeholder {
    color: #9094AF;
    // font-size: 15px;
}


.funCla::-webkit-scrollbar {
    display: none;
}
</style>
